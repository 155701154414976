@import './var-fallbacks.css';

:root {
  --letters-vertical-spacing: 3px;
  /* This should be kept somewhat in line with topbar's horizontal spacing */
  --alphabet-horizontal-margin: -12px;
}

#alphabet-panel-results {
  color: var(--sup-color-white);
  font-size: 1.44em;
  font-weight: 600;
  /* TODO: Is this a good idea? */
  margin: 0 var(--horizontal-spacing);
  text-shadow: 0 0 2px rgba(0, 0, 0, .7);
}

.alphabet-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style-type: none;
  margin-bottom: 1rem;
  padding: 0;
}

.alphabet-panel li {
  margin: var(--letters-vertical-spacing) 0.83%;
}

.alphabet-panel a {
  background: transparent;
  /* All radii are large to ensure a circle */
  border-radius: 100px;
  color: var(--sup-color-superlight);
  display: block;
  font-size: 1.44em;
  font-weight: 600;
}

.alphabet-panel li:not(.alphabet-panel__category-button):nth-child(4n + 3) a {
  color: var(--sup-color-coral);
}

.alphabet-panel li:not(.alphabet-panel__category-button):nth-child(4n) a {
  color: var(--sup-color-sunshine);
}

.alphabet-panel li a:not(.disabled):hover {
  background: var(--sup-color-superpale);
  color: var(--sup-color-superdark);
}

/* Duplicate selector to ensure these styles override the color scheme above */
.alphabet-panel li a.active.active {
  background: var(--sup-color-white);
  color: var(--sup-color-marine) !important;
}

.alphabet-panel a.disabled {
  opacity: 0.35;
}

.alphabet-panel a {
  display: block;
  width: 60px;
  height: 60px;
  line-height: 63px;
}

.alphabet-panel hr {
  border: none;
  height: 0;
  margin: 0;
  width: 100%;
}

.alphabet-panel .alphabet-panel__category-button:last-child {
  margin-right: auto;
}

.alphabet-panel .alphabet-panel__category-button a {
  font-size: 1.25rem;
  padding: 0 0.75em;
  width: auto;
}

.alphabet-panel .alphabet-panel__category-button a svg {
  fill: currentColor;
  width: 1em;
  height: 1em;
  margin-bottom: .25em;
  vertical-align: middle;
}

@media (max-width: 29.625rem) {
  .alphabet-panel {
    justify-content: space-between;
  }
}

@media (max-width: 62rem) {
  .alphabet-panel a {
    font-size: 1.1875rem;
    width: 50px;
    height: 50px;
    line-height: 52px;
  }

  .alphabet-panel .alphabet-panel__category-button a {
    font-size: 1.0625rem;
  }
}

@media (min-width: 76rem) {
  .alphabet-panel {
    margin-left: var(--alphabet-horizontal-margin);
    margin-right: var(--alphabet-horizontal-margin);
  }
}
