.live-channel--unavailable {
  background-color: rgb(0, 56, 102);
  min-height: 5em;
  width: 100%;
  margin: 0;
}

.live-channel--block {
  text-align: center;
  position: relative;
}

.live-channel--grid {
  display: grid;
  place-items: center center;
}

.live-channel--unavailable figure {
  text-align: center;
}

.live-channel--unavailable figure img {
  width: 90px;
  margin-bottom: 1rem;
}

.live-channel--unavailable figure figcaption {
  font-family: "SuperLFTEtica", sans-serif;
  font-weight: 600;
  font-size: 1.250rem;
  color: #0f1417;
}

@media(min-width: 720px) {
  .live-channel--unavailable {
    min-height: 20rem;
  }

  .live-channel--unavailable figure img {
    width: 110px;
    margin-bottom: 1.5rem;
  }
}
