:root {
  --box-size: 50px;
  --box-margin: 4px;
}

.icon-panel {
  list-style: none;
  padding-left: 0;
}

.icon-panel li {
  display: inline-block;
  /*margin-right: var(--box-margin); todo: uncomment if html is minified */
}

.icon-panel li a, .icon-panel li span {
  text-align: center;
  line-height: var(--box-size);
  width: var(--box-size);
  height: var(--box-size);
  padding: 0;
}

.icon-panel img {
  margin-top: 5px;
  width: 40px;
}
