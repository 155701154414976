.programs-panel {
  overflow: hidden;
  position: relative;
  margin-bottom: 25px;
}

.programs-panel ul {
  margin-bottom: 0;
}

.programs-panel li {
  width: 165px;
  cursor: pointer;
  vertical-align: top; /* Prevents multi-line paragraphs from pushing images above the rest of the line */
}

.programs-panel li {
  margin-right: 4px;
}

.programs-panel li:last-child {
  margin-right: 0px;
}

.programs-panel__title {
  margin-top: 0;
  margin-bottom: 4px;
  padding-left: 5px;
  line-height: 20px;
}

.programs-panel .indexpoints {
  padding-left: 0;
  transition: margin .3s ease-out;
}

.programs-panel .programs-panel__upcoming {
  cursor: default;
  opacity: .6;
}

.programs-panel__is-playing {
  position: relative;
}

.programs-panel__is-playing figure {
  position: absolute;
  top: 40px;
  left: 5px;
  margin-bottom: 0;
  font-weight: bold;
  text-shadow: 1px 1px 1px black;
}

.programs-panel__is-playing figure img.programs-panel__is-playing-now-live-icon {
  width: 50%;
}

.programs-panel__control-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 8vw;
  max-width: 60px;
}

.programs-panel__control-wrapper--left {
  background: linear-gradient(to right, rgba(0, 127, 150, 1), rgba(0, 127, 150, 0.7) 70%, transparent);
  left: 0;
}

.programs-panel__control-wrapper--right {
  background: linear-gradient(to left, rgba(0, 127, 150, 1), rgba(0, 127, 150, 0.7) 70%, transparent);
  right: 0;
}

.programs-panel__programs-control {
  position: absolute;
  top: 30%;
  cursor: pointer;
  z-index: 10;
}

.programs-panel__programs-control img {
  width: 50px;
}

.programs-panel__control-wrapper--left .programs-panel__programs-control {
  left: 10px;
}

.programs-panel__control-wrapper--left img {
  transform: rotate(180deg);
}

.programs-panel__control-wrapper--right .programs-panel__programs-control {
  right: 10px;
}
