@import './images.css';

/* Required for styling on pusle-frontend which fetches seasons from tv-web */
@import './lazy-loading.css';

:root {
  --episode-height-small-screen: 66vw;
  --episode-height-medium-screen: 20vw;
  --episode-height-large-screen: 210px;
  --expander-height: 10vw;
  --expander-max-height: calc(var(--episode-height-large-screen) / 3);
}

.seasons .season {
  margin-bottom: 45px;
  position: relative;
}

.seasons .season .episode-content {
  max-height: calc(var(--episode-height-small-screen) * .7);
  overflow: hidden;
  transition: max-height .5s ease-out;
}

/* Display a larger area for the first season */
.seasons .season:first-child .episode-content {
  max-height: calc(var(--episode-height-small-screen) * 4 * .9);
}

/* Update screenBreakThreshold in load-seasons if the min-width value is changed */
@media(min-width: 720px) {
  .seasons .season .episode-content {
    max-height: calc(var(--episode-height-medium-screen) * .7);
  }
  .seasons .season:first-child .episode-content {
    max-height: calc(var(--episode-height-medium-screen) * 4 * .9);
  }
}

@media(min-width: 1180px) {
  .seasons .season .episode-content {
    max-height: calc(var(--episode-height-large-screen) * .7);
  }
  .seasons .season:first-child .episode-content {
    max-height: calc(var(--episode-height-large-screen) * 3 + var(--episode-height-large-screen) * .7);
  }
}

.season__expander {
  position: absolute;
  height: var(--expander-height);
  max-height: var(--expander-max-height);
  width: 100%;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .8), rgba(255, 255, 255, 1));
  font-size: 38px;
  z-index: 10;
}

.season__expander img {
  height: var(--expander-height);
  max-height: var(--expander-max-height);
  position: relative;
  top: 55px;
}

.season__expander a {
  display: block;
}
