@import "./alphabet-panel.css";

@import "./content-page.css";
@import "./drop-down-container.css";
@import "./error.css";
@import "./ocean.css";
@import "./icon-panel.css";
@import "./lazy-loading.css";
@import "./programs-panel.css";
@import "./search.css";
@import "./seasons-panel.css";
@import "./series.css";
@import "./programinfo-panel.css";
@import "./submenu-panel.css";
@import "./slides.css";
@import "./live-channel.css";
@import "./bubbles-panel.css";
@import "./images.css";
@import "./pending-reskin.css";

/* TODO: remove this stmt. */
a {
  text-decoration: none;
}

.sup-tv-web-body {
  background-color: var(--sup-color-marine);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Mobile devices do not support background-attachment: fixed */
@media (max-device-width: 1024px) {
  .sup-tv-web-body {
    /* First is header gradient; second is a vertically repeating background */
    background-image: url(https://gfx.nrk.no/tBTvR6y-jKP7bbEFSWCDdww17AWD96L0X32yMWa81PcQ), url(https://gfx.nrk.no/IxGVEmFjymc8UlKr8nm49QnH9Lq79lPUUdZJy9ya7uiw);
    background-position: top center;
    background-repeat: repeat-x, repeat-y;
  }
}

@media (min-device-width: 1025px) {
  .sup-tv-web-body {
    /* First is header gradient; second is a fixed and covering background */
    background-image: url(https://gfx.nrk.no/N_jtCV48VakLMFM8tS0LhQkhWn5PQjcO-2rekXoTQGuw), url(https://gfx.nrk.no/p7GbHYX3jZyI-T4WJGoZwAWqhkyNtJLSofKbt818nNxw);
    background-attachment: scroll, fixed;
    background-position: top, center;
    background-repeat: repeat-x, no-repeat;
    background-size: auto, cover;
  }
}

.sup-tv-web-content-container {
  flex: 1 0 auto;
  width: 100%;
}

.fullsize {
  width: 100%;
}

hr {
  color: #ddd;
  background-color: #ddd;
  height: 1px;
  border: 0;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
}

@media (max-width: 720px) {
  .sup-sm-prn {
    padding-right: 0;
  }
}

/*********************************************************
  Webplayer extensions
 *********************************************************/
.indexpoints li {
  display: inline-block;
  list-style: none;
}
