.series .series-title h2 {
  font-size: 2rem;
  line-height: 2rem;
}

.series .season__expander img {
  transform: rotate(90deg);
}

.series .external-links {
  list-style: none;
  padding: 0;
}
.series .external-links a {
  text-decoration: underline;
}

.series .sup-age-icon, .series .sup-clock {
  width: 1.5rem;
  height: 1.5rem;
  padding-bottom: 5px;
}

