.programinfo-panel-title h2 {
  font-size: 2rem;
  line-height: 2rem;
}

.programinfo-panel .programinfo-panel-subtitle {
  display: inline;
  margin: 0;
  padding: 0;
}

.programinfo-panel .external-links {
  list-style: none;
  padding: 0;
}

.programinfo-panel .external-links a {
  text-decoration: underline;
}

.programinfo-panel .sup-age-icon,
.programinfo-panel .sup-clock {
  width: 1.5rem;
  height: 1.5rem;
  padding-bottom: 5px;
}
