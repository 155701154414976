.lazyload-container-dark, .lazyload-container-light {
  border-radius: var(--image-border-radius);
}

.lazyload-container-dark {
  background-color: var(--sup-color-marine);
}

.lazyload-container-light {
  background-color: #F3FEFF;
}

/* Automatically added by https://github.com/aFarkas/lazysizes */
.lazyload, .lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}
