@import './var-fallbacks.css';

:root {
  --header-height: 114px;
  --bubbles-offset: 60px;
}

.drop-down-container {
  background: var(--sup-color-marine);
  margin: calc(-1 * var(--header-height)) 0 calc(-1 * var(--bubbles-offset)) 0;
  padding: var(--header-height) 0 calc(var(--bubbles-offset) + 1.5rem) 0;
}
