@import './var-fallbacks.css';

:root {
  --border-width: 30px;
  --half-border-width: 15px;
  --image-border-radius: 3px;
  --hover-transition-duration: 0.2s;

  --small-element-hover-scale: 1.03;
  --large-element-hover-scale: 1.015;
  --promo-element-hover-scale: 1.0075;
}

.ocean {
  padding: calc(15px - var(--half-border-width));
}

.sup-tv-web-content-container .ocean {
  margin-bottom: 4rem;
}

.ocean img {
  /* Enforce 16:9, even if the image is scaled incorrectly */
  height: 100%;
}

.ocean__element {
  position: relative;
  padding: var(--half-border-width);
  padding-bottom: 0;
}

.ocean__element a {
  display: block;
  position: relative;
}

.ocean__element-image-container {
  display: block;
  content: "";
  width: 100%;
  /* Aspect ratio: 16:9 */
  padding-top: 56.25%;
  position: relative;
  border-radius: var(--image-border-radius);
  transition: box-shadow var(--hover-transition-duration), transform var(--hover-transition-duration);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}

.ocean__element-image-container img.ocean__element-thumbnail, .ocean__element .sup-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: var(--image-border-radius);
}

.ocean__element-image-container img.ocean__element-now-icon {
  position: absolute;
  height: 40%;
  left: 10px;
  bottom: 0;
  z-index: 3;
}

/* TODO: Where is this used? */
.ocean__element .sup-loader {
  top: 35%;
}

.ocean__element-slot-wrapper {
  position: absolute;
  top: 0;
  left: var(--border-width);
  z-index: 3;
  border-radius: 3px;
  font-size: .8em;
}

.ocean__element-type-wrapper {
  position: absolute;
  top: 0;
  right: var(--border-width);
  z-index: 3;
  border-radius: 3px;
  font-size: .8em;
}

.ocean__element h2 {
  color: var(--sup-color-supershine);
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.2em;
  padding: var(--sup-pad-large) var(--sup-pad-small);
  text-shadow: 0px 0px 2px rgba(0, 0, 0, .7);
  word-break: break-word;
}

.ocean__element h2 .secondary-title {
  color: var(--sup-color-white);
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.25em;
  padding-top: 1px;
}

/* Hover states */

.ocean__element a:hover .ocean__element-image-container, .ocean__element a:focus .ocean__element-image-container {
  box-shadow: 0 5px 9px 0px rgba(0, 0, 0, 0.27);
}

.ocean__element.ocean__element--small a:hover .ocean__element-image-container, .ocean__element.ocean__element--small a:focus .ocean__element-image-container {
  transform: scale(var(--small-element-hover-scale));
}

.ocean__element.ocean__element--large a:hover .ocean__element-image-container, .ocean__element.ocean__element--large a:focus .ocean__element-image-container {
  transform: scale(var(--large-element-hover-scale));
}

.ocean__element:not(.ocean__promo) a:hover h2, .ocean__element:not(.ocean__promo) a:focus h2 {
  color: var(--sup-color-white);
}

/* Active states */

.ocean__element a:active .ocean__element-image-container {
  box-shadow: none;
}

.ocean__element.ocean__element--small a:active .ocean__element-image-container {
  transform: scale(calc(2 - var(--small-element-hover-scale)));
}

.ocean__element.ocean__element--large a:active .ocean__element-image-container {
  transform: scale(calc(2 - var(--large-element-hover-scale)));
}

/* promo */
.ocean__promo.ocean__element {
  /* Ensures the promo has a proper box shadow as the heading is laid on top */
  padding-bottom: calc(2 * var(--half-border-width));
}

.ocean__promo.ocean__element h2 {
  background: var(--sup-color-white);
  color: var(--sup-color-black);
  min-height: auto;
  text-shadow: none;
}

/* Mobile */
@media (max-width: 44.99rem) {
  :root {
    --half-border-width: 8px;
  }

  .ocean__element {
    padding-bottom: var(--half-border-width);
  }

  .ocean__promo.ocean__element a {
    border-radius: var(--image-border-radius);
    border-bottom-right-radius: 55px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
    transition: box-shadow var(--hover-transition-duration), transform var(--hover-transition-duration);
  }

  /* Mobile hover and active state */

  .ocean__element.ocean__promo a:hover, .ocean__element.ocean__promo a:focus {
    box-shadow: 0 5px 9px 0px rgba(0, 0, 0, 0.27);
    transform: scale(var(--promo-element-hover-scale));
  }

  .ocean__element.ocean__promo a:active {
    box-shadow: none;
    transform: scale(calc(2 - var(--promo-element-hover-scale)));
  }

  /* Promo heading lifter over the image, masking the image box shadow */
  .ocean__promo.ocean__element h2 {
    border-radius: 0 0 55px var(--image-border-radius);
    font-size: 1.25rem;
    padding: 20px 40px 20px 20px;
    position: relative;
    z-index: 3;
  }

  .ocean__promo .ocean__element-image-container {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    /* Aspect ratio: 2:1 */
    padding-top: 50%;
  }

  /* Visually attaches promo thumbnail to its heading (see below) */
  .ocean__promo .ocean__element-image-container img.ocean__element-thumbnail {
    /* Clip / safe zone behaviour for promo thumbnail */
    left: -52%;
    max-width: none;
    width: auto;
  }
}

/* Tablet and up (including desktop) */
@media (min-width: 45rem) {
  :root {
    --half-border-width: 14px;
  }

  .ocean__promo .ocean__element-image-container {
    /* Non-mobile aspect ratio: 295:86 */
    padding-top: 29.15%;
  }

  /* Non-mobile hover and active state */
  .ocean__element.ocean__promo a:hover .ocean__element-image-container, .ocean__element.ocean__promo a:focus .ocean__element-image-container {
    transform: scale(var(--promo-element-hover-scale));
  }

  .ocean__element.ocean__promo a:active .ocean__element-image-container {
    transform: scale(calc(2 - var(--promo-element-hover-scale)));
  }

  .ocean__element h2 {
    min-height: 3.3em;
  }

  .ocean__element h2 .secondary-title {
    display: inline;
    font-size: 1.05em;
    margin-left: 6px;
  }

  .ocean__promo.ocean__element h2 {
    /* The right-side radii are large to ensure they always form a half circle */
    border-radius: var(--image-border-radius) 50px 50px var(--image-border-radius);
    font-size: calc(13.739px + 0.87vw);
    padding: 2% 3% 2% 30px;
    position: absolute;
    bottom: 10%;
    left: calc(-0.5 * var(--border-width));
    z-index: 2;
    max-width: 45%;
  }
}

/* Desktop and up */
@media (min-width: 74.5rem) {
  .ocean__promo.ocean__element h2 {
    font-size: 1.5rem;
  }
}

/* Between tablet and desktop */
@media (min-width: 45rem) and (max-width: 74.5rem) {
  :root {
    --half-border-width: 1vw;
  }

  /* Visually attaches promo heading to left side of viewport */
  .ocean__promo.ocean__element h2 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
