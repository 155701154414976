.sup-tv-web-body img:not(.ludo-poster__image) {
  border-radius: 3px;
}

.active-episode {
  background-color: var(--sup-color-supershade);
  color: var(--sup-color-white);
}

.sup-tv-web-body .active-episode {
  background-color: var(--sup-color-white);
}

.sup-tv-web-body .active-episode img {
  border: 5px solid var(--sup-color-superchalk);
  border-radius: 3px;
  transition-duration: 0.15s;
}

figure figcaption {
  line-height: 1.2;
  padding-bottom: 1.65rem;
  padding-left: .3em;
  padding-right: .3em;
}

.sup-tv-web-body figure figcaption {
  font-weight: 600;
  padding-top: 0.5em;
  background-color: var(--sup-color-white);
  color: var(--sup-color-black);
}
