.pending-reskin-container {
  background-color: var(--sup-color-white);
  border-radius: 6px;
  padding: 15px;
  margin: 15px;

  /* Required to prevent margins from collapsing; example: /direkte */
  border-bottom: 1px solid transparent;
}

.pending-reskin-container.no-padding-top {
  padding-top: 0;
}

.pending-reskin-container.no-padding-bottom {
  padding-bottom: 0;
}

/* Used for content that should be presented as-is (without padding adjustments
or a white box) but should respect margins on desktop (full width on mobile) */
.pending-reskin-container.margin-only {
  border-bottom: none;
  padding: 0;
}

@media (max-width: 45rem) {
  .pending-reskin-container {
    border-radius: 0;
    margin: 15px 0;
  }

  /* Used for content that should break out of the container it is in, such as
  a top-image or the main video */
  .pending-reskin-container .pending-reskin-break-out {
    margin: -15px -15px 0 -15px;
  }
}
