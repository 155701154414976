.sup-search-form__textfield:focus {
  outline: 0;
}

.sup-search-form__textfield.sup-color-supershade::placeholder {
  color: #ccc;
}

.sup-search-form__textfield:focus::placeholder {
  color:transparent;
}

.sup-search-form__textfield {
  padding-left: 22px;
}
