.bubbles-container {
  position:relative;
  margin: 15px 0;
  overflow: hidden;
}

.transition-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.background-bubbles {
  position:relative;
  min-height: 90px;
  background-image: url('../../public/imgs/bubbles/boble_mobil.png');
  background-repeat: repeat-x;
  transition-duration: 0 ms;
  transition: transform linear;
}

.background-bubbles-margin-left {
  margin-left: 15px;
}

.foreground-bubbles {
  display: flex;
  position:absolute;
  top: 0;
  margin: 0;
  padding:0;
  list-style: none;
}

.background-bubbles-left .foreground-bubbles li {
  margin-right: 10px;
}

.foreground-bubbles li {
  flex: 1 0 auto;
  width: 90px;
  height: 90px;
  margin: 0;
  margin-right: 18px;
  box-sizing: border-box;
  background: #00b4c3;
  border: 5px solid var(--sup-color-superchalk);
}

.sup-tv-web-body .foreground-bubbles li, .sup-tv-web-body .foreground-bubbles li img,
.sup-tv-remote-body .foreground-bubbles li, .sup-tv-remote-body .foreground-bubbles li img{
  border-radius: 50%;
  vertical-align: middle;
}

.foreground-bubbles a {
  display: block;
  position: relative;
  text-decoration: none;
}

.single-bubble:hover, .single-bubble:focus-within {
  background: white;
  border: 5px solid var(--sup-color-superpale);
}

.bubbles-container ::-webkit-scrollbar {
  display: none;
}

/* Note: Cannot combine these with the one above due to IE11 compatibility */
.scroll-arrow, .bubble-title {
  display: none;
}

.bubble-title {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  z-index: 20;
}

.bubbles-container * {
  scrollbar-width: none;
}

@media(min-width: 48.125rem) {
  .bubbles-container {
    margin: 15px 0 0;
  }

  .background-bubbles {
    background-image: url('../../public/imgs/bubbles/boble_ipad-venstre.png');
  }

  .align-bubbles {
    left: 50%;
    transform: translateX(-50%);
  }

  .background-bubbles-margin-left {
    margin-left: 0px;
  }

  .background-bubbles-middle-odd .foreground-bubbles li, .background-bubbles-middle-even .foreground-bubbles li {
    margin: 0 9px;
  }

  .background-bubbles-left {
    background-image: url('../../public/imgs/bubbles/boble_ipad-venstre.png');
  }

  .background-bubbles-left .foreground-bubbles li {
    margin-right: 17px;
  }

  .background-bubbles-middle-even {
    background-position: 50% 50%;
    background-image: url('../../public/imgs/bubbles/boble_ipad-even.png');
  }

  .background-bubbles-middle-odd {
    background-position: 50% 50%;
    background-image: url('../../public/imgs/bubbles/boble_ipad-odd.png');
  }

  .single-bubble:hover .bubble-title, .single-bubble:focus-within .bubble-title {
    display: block;
  }

  .single-bubble:first-child:hover .bubble-title-left, .single-bubble:first-child:focus-within .bubble-title-left {
    left: 0;
    transform: none;
  }

  .bubble-title-text {
    display: inline-block;
    padding: 6px 12px;
    background-color: var(--sup-color-marine);
    border-radius: 30px;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    color: #fff;
  }

  /* Scroll styles */
  .scroll-arrow {
    position: absolute;
    top: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: var(--sup-color-supershade);
    cursor: pointer;
    color: #fff;
    opacity:0.9;
  }

  .scroll-arrow svg {
    width: 24px;
    height: 24px;
  }

  #arrow-right {
    right: -43px;
  }

  #arrow-right svg {
    margin-right: 30px;
  }

  #arrow-left {
    left: -43px;
    z-index:20;
  }

  #arrow-left svg {
    margin-left: 30px;
  }
}

@media (min-width: 992px) {
  .bubbles-container {
    margin: 15px 0 20px 0;
    margin-left: 0;
  }

  .background-bubbles {
    min-height: 114px;
    background-image: url('../../public/imgs/bubbles/boble_midten-even.png');
  }

  .foreground-bubbles li {
    width: 114px;
    height: 114px;
  }

  .background-bubbles-middle-odd .foreground-bubbles li, .background-bubbles-middle-even .foreground-bubbles li {
    margin:0 22px 0 0;
  }

  .background-bubbles-left .foreground-bubbles li {
    margin-right: 22px;
  }

  .background-bubbles-left {
    background-image: url('../../public/imgs/bubbles/boble_venstre-odd.png');
  }

  .background-bubbles-middle-odd {
    background-position: 50% 50%;
    background-image: url('../../public/imgs/bubbles/boble_midten-odd.png');
  }

  .background-bubbles-middle-even {
    background-position: 50% 50%;
    background-image: url('../../public/imgs/bubbles/boble_midten-even.png');
  }

  .background-bubbles-middle-even .foreground-bubbles, .background-bubbles-middle-odd .foreground-bubbles {
    margin-left: 11px;
  }

  .scroll-arrow {
    width: 114px;
    height: 114px;
  }

  .scroll-arrow svg {
    width: 36px;
    height: 36px;
  }

  #arrow-right {
    right: -46px;
  }

  #arrow-left {
    left: -46px;
    z-index:20;
  }
}

.scroll-hide {
  display: none;
}
